<template>
  <div>
    <!--  -->
    <el-dialog
      title="修改会员"
      :visible.sync="dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeAlterMemberDialog"
      width="900px"
      top="2%"
    >
      <el-form
        :model="initComponentData.editData"
        ref="ruleForm"
        label-width="80px"
        label-position="left"
        class="demo-ruleForm"
      >
        <div class="auto">
          <div class="content">
            <!-- dialog-top -->
            <div class="dialog-top">
              <div class="left">
                <DFUpload
                  ref="DFUpload"
                  @success="onSubmitForm"
                  @error="onUploadError"
                  @remove="onRemove"
                >
                </DFUpload>
              </div>
              <div class="right">
                <div>
                  <div class="box">
                    <span>{{ memberInfo.MemberName }}</span>
                  </div>
                  <div class="phone">
                    {{ memberInfo.MemberPhone }}
                  </div>

                  <div class="status">
                    <span class="dfWebIcon dfWebIcon-yiguanzhu">{{
                      initComponentData.editData.SubscribeMark
                        ? "已关注"
                        : "未关注"
                    }}</span>
                    <span class="dfWebIcon dfWebIcon-bangding">{{
                      initComponentData.editData.WechatMark
                        ? "已绑定"
                        : "未绑定"
                    }}</span>
                    <el-button
                      v-if="initComponentData.editData.WechatMark"
                      type="danger"
                      size="mini"
                      @click="resetWechatMark"
                      >解除绑定</el-button
                    >
                  </div>
                </div>
              </div>
              <div class="location">
                <el-button size="mini">添加标签</el-button>
              </div>
            </div>
            <!-- end dialog-top -->
            <div class="content-bottom" v-if="initComponentData.totalBalance">
              <ul class="content-ul">
                <li>
                  <div>{{ initComponentData.totalBalance.Balance }}</div>
                  <div>余额</div>
                </li>
                <li>
                  <div>{{ initComponentData.totalBalance.DonaBalance }}</div>
                  <div>赠送余额(元)</div>
                </li>
                <li>
                  <div>{{ initComponentData.totalBalance.InteBalance }}</div>
                  <div>积分</div>
                </li>
                <li>
                  <div>{{ initComponentData.totalBalance.DebtBalance }}</div>
                  <div>欠款(元)</div>
                </li>
                <li>
                  <div>
                    <span>{{ initComponentData.editData.RegisterDate }}</span>
                  </div>
                  <div>建档时间</div>
                </li>
              </ul>
            </div>
          </div>
          <!-- end content -->
          <!-- dialog-center -->
          <div class="dialog-center">
            <div class="tabs">
              <div
                v-for="(item, index) in tabsData"
                :key="index"
                :class="isAction == index ? 'action' : ''"
                @click="onTabs(index)"
              >
                {{ item }}
              </div>
            </div>
            <div class="tabs-content">
              <PersonalInformation
                ref="PersonalInformation"
                v-show="isAction == 0"
                :editData="initComponentData.editData"
                :emplSelector="initComponentData.emplSelector"
                :totalBalance="initComponentData.totalBalance"
                :clientSource="initComponentData.clientSource"
                @modifySuccess="closeAlterMemberDialog"
                @submitForm="onUploadPicture"
              >
              </PersonalInformation>
              <!-- 记录 -->
              <Record ref="Record" v-show="isAction == 1"> </Record>
              <!-- 积分 -->
              <Integral
                ref="Integral"
                v-show="isAction == 2"
                :inteType="initComponentData.inteType"
                :recordType="initComponentData.recordType"
                :vipSelector="initComponentData.vipSelector"
              >
              </Integral>
              <!-- 疗程 -->
              <CourseCard v-show="isAction == 3" :vipData="cardData">
              </CourseCard>
              <!-- 优惠券 -->
              <Coupon v-show="isAction == 4" :tableData="couponData"> </Coupon>
              <!-- 会员卡 -->
              <VipCard v-show="isAction == 5" :vipData="cardData"> </VipCard>
            </div>
          </div>
        </div>
        <div class="dialog-bottom">
          <el-form-item>
            <el-button type="info" size="small" @click="closeAlterMemberDialog"
              >取消</el-button
            >
            <el-button type="primary" @click="onSubmit" size="small"
              >确认</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import VipCard from "./child/vip-card.vue";
import PersonalInformation from "./child/personal-information.vue";
import CourseCard from "./child/course-card.vue";
import Record from "./child/record.vue";
import Coupon from "./child/coupon.vue";
import Integral from "./child/integral.vue";
import api from "@/api/member.js";
import { baseURL } from "@/utils/axios.js";
export default {
  components: {
    PersonalInformation,
    VipCard,
    CourseCard,
    Record,
    Coupon,
    Integral,
  },
  data() {
    return {
      dialog: false,
      dialogImageUrl: "",
      disabled: false,
      ruleForm: {},
      isAction: 0,
      tabsData: ["个人信息", "记录", "积分明细", "疗程", "优惠券", "会员卡"],
      initComponentData: {
        editData: {
          SubscribeMark: 0,
          WechatMark: 0,
        },
      },
      cardData: [],
      couponData: [],
      memberInfo: {},
      urlAction:
        baseURL +
        "/member/save_member_picture?acctoken=" +
        window.sessionStorage.getItem("token"),
      fileList: [],
    };
  },

  mounted() {},

  methods: {
    async initSaveMemberData() {
      let {memberInfo} = this
      try {
        let imageArr = [];
        let submitData = {
          subact: "update",
          member_guid: memberInfo.MemberGuid,
        };
        let { data } = await api.initSaveMemberData(submitData);
        this.initComponentData = await data;
        this.dialog = true;

        if (await data.editData.PictureUrl) {
          // this.fileList.push({ url: data.editData.Picture });
          await imageArr.push({ url: data.editData.PictureUrl });
        }
        await this.$refs.DFUpload.initImageList(imageArr);

        let res = await api.memberVipList({ member_guid: memberInfo.MemberGuid });
        this.cardData = res.data;

        let res3 = await api.getMemberCouponList({
          member_guid: memberInfo.MemberGuid,
          member_phone: memberInfo.MemberPhone,
        });

        this.couponData = res3.data;
      } catch (e) {}
    },

    openAlterDialog(event) {
      this.memberInfo = event;
      this.initSaveMemberData()
    },

    onUploadError() {},

    async onRemove(file) {
      await this.$confirm(`是否删除该图片`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let { data, errcode, errmsg } = await api.deleteMemberPricture({
          member_guid: this.initComponentData.editData.MemberGuid,
        });
        if (errcode == 0) {
          await this.$message.success("会员头像删除成功");
          await this.$refs.DFUpload.onRemove(file);
          await this.$emit("success");
          await this.closeAlterMemberDialog();
        }
      });
    },

    resetWechatMark() {
      this.$confirm("确定要解除手机绑定吗？", "解绑提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await api.resetWechatMark({
            member_guid: this.initComponentData.editData.MemberGuid,
          });
          if (errcode == 0) {
            this.$message.success("解绑成功");
            this.initSaveMemberData()
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    handleRemove(file) {
      console.log(file);
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
    },

    handleDownload(file) {
      console.log(file);
    },

    onTabs(index) {
      if (index == 1) {
        this.$refs.Record.initComponentData(this.memberInfo);
      } else if (index == 2) {
        this.$refs.Integral.initComponentData(this.memberInfo.MemberGuid);
      }

      this.isAction = index;
    },

    onSubmit() {
      // console.log("ddd", this.initComponentData.editData);
      this.$refs.DFUpload.submitUpload(
        this.initComponentData.editData.Picture ? "" : this.urlAction
      );
    },

    closeAlterMemberDialog() {
      this.isAction = 0;
      this.$refs.Integral.clearComponentData();
      this.$refs.Record.clearComponentData();
      this.$refs.DFUpload.initImageList();
      this.dialog = false;
    },

    uploadSuccess(response, file, fileList) {
      if (response.errcode !== 0) {
        this.$message.error(response.errmsg);
      } else {
        this.initComponentData.editData.Picture = response.data;
      }
      this.onSubmitForm();
    },

    uploadRrror(err) {
      this.$message.error(err.errmsg);
    },

    onUploadPicture(event) {
      if (this.fileList.length > 1) {
        this.$refs.upload.submit();
      } else {
        this.onSubmitForm();
      }
    },

    async onSubmitForm(event) {
      let imageUrl = (await event) ? event.data : "";
      this.initComponentData.editData.Picture = (await imageUrl)
        ? imageUrl
        : this.initComponentData.editData.Picture;

      // console.log(this.initComponentData.editData, imageUrl)
      try {
        console.log(this.initComponentData.editData);
        let { data, errcode, errmsg } = await api.saveMember(
          this.initComponentData.editData,
          "update"
        );
        if (errcode == 0) {
          await this.$message({
            message: "修改成功!",
            type: "success",
          });
          await this.$emit("success");
          await this.closeAlterMemberDialog();
        } else {
          await this.$message.error(errmsg);
        }
      } catch (e) {}
    },
  },
};
</script>

<style lang="less" scoped>
.auto {
  height: 700px;
  overflow-y: scroll;
}
.auto::-webkit-scrollbar-track {
  background-color: #ffffff;
}
.auto::-webkit-scrollbar {
  width: 3px;
}
.auto::-webkit-scrollbar-thumb {
  background-color: #999999;
}
.content {
  border: 1px solid #dcdfe6;
}
.dialog-top {
  display: flex;
  align-items: center;
  min-height: 200px;
  box-sizing: border-box;
  padding: 20px 0;
  position: relative;
  .left {
    max-width: 470px;
    margin: 0 20px;
  }
  .right {
    height: 100%;
    display: flex;
    align-items: center;
    .box {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 22px;
      i {
        font-size: 28px;
        margin: 0 10px;
      }
    }
    .phone {
      font-size: 16px;
      margin-top: 10px;
    }
    .status {
      margin-top: 10px;
      span {
        margin-right: 10px;
        font-size: 14px;

        &::before {
          color: tomato;
          font-size: 18px;
        }
      }

      .el-button {
        // margin-left: 10px;
        padding: 5px;
      }
    }
  }
  .location {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.content-bottom {
  border-top: 1px solid #dcdfe6;
  height: 80px;
  margin-top: 0;
  .content-ul {
    display: flex;
    padding: 20px;
    > li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      height: 40px;
      padding: 0 10px;
      border-right: 1px solid #dcdfe6;
      > div:first-child {
        font-size: 16px;
        i {
          margin-left: 10px;
        }
      }
      > div:last-child {
        color: #8492a6;
      }
    }
    > li:last-child {
      border-right: 0;
    }
  }
}
.dialog-center {
  //height: 500px;
  margin-top: 20px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  .tabs {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    border-bottom: 1px solid #dcdfe6;
    box-sizing: border-box;
    margin-bottom: 20px;
    > div {
      display: flex;
      align-items: center;
      height: 40px;
      margin-right: 40px;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
      transition: all 0.5s;
    }
    .action {
      border-bottom: 2px solid #409eff;
    }
  }
  .tabs-chile {
    padding: 0 20px;

    .el-form-item {
      margin-bottom: 6px;
    }
  }
  .tabs-content {
    padding: 0 20px;
  }
}

.dialog-bottom {
  margin-top: 20px;
  .el-form-item {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
}
</style>
